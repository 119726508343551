import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const HistoricoPage = () => (
  <Layout>
    <SEO title="Podcast" />
    <section className="intro">
      <div className="intro-container">
        <h2>Histórico</h2>
        <p>Mais informações chegando em breve.</p>
      </div>
    </section>
    <section className="content">
      <div className="content-container">
        <p>Os esforços para realização do evento iniciaram em outubro de 2019, em conformidade ao crescente interesse pelo fetiche de látex/borracha, registrado em eventos e festas fetichistas em São Paulo, principalmente, mas também registrado em outras capitais brasileiras.</p>
        <p>A iniciativa busca promover e disseminar não apenas o fetiche de látex em si, mas a diversidade humana em se manifestar, inclusive no meio fetichista brasileiro, na organização do mesmo encontra-se diversos expoentes da cena brasileira e internacional, que buscam criar um espaço e movimento democrático que estejam presentes a criatividade, diversão e sensualidade, por meio de ações de divulgação sadia do fetichismo envolvido com o látex.</p>
        <p>Em outubro de 2019, o Mr. Rubber eleito foi Mestre Marcus. De 2019 a 2022, houve um hiato em relação à promoção do evento, em conformidade com a pandemia da Covid-19, que exigiu inicial distanciamento social.</p>
        <p>Retomamos o evento no ano de 2022, após a orientação de liberação de eventos pelos protocolos governamentais.</p>
        <p><Link to="/" className="call-to-action">Voltar à página inicial</Link></p>
      </div>
    </section>
  </Layout>
)

export default HistoricoPage
